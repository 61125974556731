import {FETCH_SINGLE_EQUIPMENT} from '../Constants'

const initialState = {}

function singleEquipmentReducer(state=initialState,action){
    switch(action.type){
        case FETCH_SINGLE_EQUIPMENT:
            return action.payload
        default: 
            return state
    }
}


export default singleEquipmentReducer