import {SET_USER_DETAILS} from '../Constants'

const initialState = {}

function userDetailsReducer(state =initialState,action){
    switch(action.type){
        case SET_USER_DETAILS:
            return action.payload
        default:
            return state
    }
}

export default userDetailsReducer