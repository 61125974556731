import {FETCH_EQUIPMENT_CATEGORIES} from '../Constants'

const initialState = []

function equipmentCategoryReducer(state=initialState,action){
    switch(action.type){
        case FETCH_EQUIPMENT_CATEGORIES:
            return action.payload
        default: 
            return state
    }
}


export default equipmentCategoryReducer