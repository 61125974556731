import {IS_APPROVED} from '../Constants'

const initialState = false

function isApprovedReducer(state =initialState,action){
    switch(action.type){
        case IS_APPROVED:
            return action.payload
        default:
            return state
    }
}

export default isApprovedReducer