import {SET_VENDOR_DETAILS} from '../Constants'

const initialState = {}

function vendorDetailsReducer(state =initialState,action){
    switch(action.type){
        case SET_VENDOR_DETAILS:
            return action.payload
        default:
            return state
    }
}

export default vendorDetailsReducer