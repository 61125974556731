import {FETCH_LOCATIONS} from '../Constants'

const initialState = []

function locationReducer(state=initialState,action){
    switch(action.type){
        case FETCH_LOCATIONS:
            return action.payload
        default: 
            return state
    }
}


export default locationReducer