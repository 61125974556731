import {DIRECTION} from '../Constants'

function directionReducer(state = 'ltr', action) {
    switch (action.type) {
        case DIRECTION:
            return action.payload
        default:
            return state
    }
    // console.log(state)
}

export default directionReducer