export const equipmentCollection = 'equipments'
export const equipmentCategoriesCollection = 'equipmentCategories'
export const cartCollection = 'cart'
export const userCollection = 'users'
export const orderRequestCollection = 'orderRequest'
export const orderCollection = 'orders'
export const locationCollection = 'locations'
export const servicesCollection = 'services'
export const serviceCategoriesCollection = 'serviceCategories'
export const contactCollection = 'contactUs'
export const mainCategoryCollection = 'equipmentMainCategories'
export const subOrderCollection = 'subOrders'
export const ratingCollection = 'ratings-reviews'
export const vendorMessageCollection = 'vendor-messages'
export const vendorCollection = 'vendors'
export const configurationCollection = 'configurations'
export const countryCodeCollection = 'countryCodes'


// status states
export const cartActiveStatus = 'ACTIVE'

export const orderAdminApprovedStatus = 'ADMIN_APPROVED'
export const orderVendorAcceptStatus = 'VENDOR_ACCEPTED'
export const orderVendorRejectStatus = 'REJECTED_BY_VENDOR'
export const orderDispatchedStatus = 'DISPATCHED'
export const orderDeliveredStatus = 'DELIVERED'
export const orderAdminRejectStatus = 'REJECTED_BY_ADMIN'
export const orderUserCancelStatus = 'CANCELLED_BY_USER'
export const orderPendingApprovalStatus = 'PENDING_APPROVAL'

export const statusToAviod = [orderPendingApprovalStatus,orderUserCancelStatus,orderAdminRejectStatus]

export const labelObject = {
    [orderAdminApprovedStatus] : 'Pending',
    [orderVendorAcceptStatus] : 'Order Accepted',
    [orderVendorRejectStatus] : 'Order Rejected',
    [orderDispatchedStatus] : 'Order Dispatched',
    [orderDeliveredStatus] : 'Order Delivered',
    [orderAdminRejectStatus] :"Order Rejected By Admin",
    [orderUserCancelStatus] : "Order Cancelled By User",
    [orderPendingApprovalStatus] : 'Pending Approval'
}

export const successStatuses = [orderAdminApprovedStatus,orderVendorAcceptStatus,orderDispatchedStatus,orderDeliveredStatus,orderPendingApprovalStatus]
export const failedStatuses  = [orderAdminRejectStatus,orderUserCancelStatus,orderVendorRejectStatus]
export const actionStatuses = [orderAdminApprovedStatus,orderVendorAcceptStatus,orderDispatchedStatus]
export const reportAvoidStatus = [orderPendingApprovalStatus,orderUserCancelStatus]
export const currentOrderStatuses = [orderAdminApprovedStatus,orderVendorAcceptStatus,orderDispatchedStatus]
export const pastOrderStatuses = [orderAdminRejectStatus,orderVendorRejectStatus,orderDeliveredStatus]
export const successfulOrderStatus = [orderDeliveredStatus]
export const failedOrderStatus = [orderAdminRejectStatus,orderVendorRejectStatus,orderUserCancelStatus]

export const dailyPriceFilterMax = 'daily-price-filter-max'
export const dailyPriceFilterMin = 'daily-price-filter-min'