function retry(fn, retriesLeft = 3, interval = 500) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              reject(error);
              window.location.reload()
              return;
            }
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
}
export default retry
