import {SET_USER_UID} from '../Constants'

const initialState = {uId: '',isAnonymous: true}

function uIdReducer(state =initialState,action){
    switch(action.type){
        case SET_USER_UID:
            return action.payload
        default:
            return state
    }
}

export default uIdReducer