import {IS_VENDOR} from '../Constants'

const initialState = false

function isVendorReducer(state =initialState,action){
    switch(action.type){
        case IS_VENDOR:
            return action.payload
        default:
            return state
    }
}

export default isVendorReducer