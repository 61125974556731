import {FETCH_SINGLE_SERVICE} from '../Constants'

const initialState = {}

function singleServiceReducer(state=initialState,action){
    switch(action.type){
        case FETCH_SINGLE_SERVICE:
            return action.payload
        default: 
            return state
    }
}


export default singleServiceReducer