import {FETCH_SERVICES} from '../Constants'

const initialState = []

function servicesReducer(state=initialState,action){
    switch(action.type){
        case FETCH_SERVICES:
            return action.payload
        default: 
            return state
    }
}


export default servicesReducer