const Routes = {
    home: '/home',
    equipments : '/equipments',
    addEquipment: '/equipments/add',
    services: '/services',
    ratings: '/ratings',
    addService: '/services/add',
    support: '/support',
    report : '/reports',
    information: '/information',
    signin:'/',
    signup:'/sign-up',
    cart: '/order',
    order:'/order',
    profile:'/profile',
    privacyPolicy: '/privacy-policy',
    cookiePolicy: '/cookie-policy',
    loader:'/loader'
}

export default Routes