import {FETCH_MAIN_CATEGORIES} from '../Constants'

const initialState = []

function mainCategoryReducer(state=initialState,action){
    switch(action.type){
        case FETCH_MAIN_CATEGORIES:
            return action.payload
        default: 
            return state
    }
}


export default mainCategoryReducer