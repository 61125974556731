import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
let Theme = createMuiTheme({
    palette:{
        primary:{
            light:"#666ad1",
            main:'#303f9f',
            dark:'#001970',
            contrastText:"#ffffff"
        },
        secondary:{
            light:"#6effe8",
            main:'#1de9b6',
            dark:'#00b686',
            contrastText:"#000000"
        }
    },
    typography:{
        fontFamily: ['Nunito','sans-serif'].join(',')
    }
})

Theme = responsiveFontSizes(Theme)

export default Theme
