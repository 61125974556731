import {FETCH_COUNTRY_CODES} from '../Constants'

const initialState = []

function countryCodeReducer(state=initialState,action){
    switch(action.type){
        case FETCH_COUNTRY_CODES:
            return action.payload
        default: 
            return state
    }
}


export default countryCodeReducer