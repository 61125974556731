import {FETCH_RATINGS} from '../Constants'

const initialState = []

function ratingReducer(state=initialState,action){
    switch(action.type){
        case FETCH_RATINGS:
            return action.payload
        default: 
            return state
    }
}


export default ratingReducer