import {FETCH_SERVICE_CATEGORIES} from '../Constants'

const initialState = []

function serviceCategoryReducer(state=initialState,action){
    switch(action.type){
        case FETCH_SERVICE_CATEGORIES:
            return action.payload
        default: 
            return state
    }
}


export default serviceCategoryReducer